<template>
  <div class="p-grid crud-demo">
    
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2 p-mb*2"
              @click="openNew"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="setores"
          class="p-datatable-sm p-datatable-striped"
          v-model:selection="selectedSetores"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} setores"
        >
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Setores</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global']"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>

          <Column field="sigla" header="Sigla" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Sigla</span>
              {{ slotProps.data.sigla }}
            </template>
          </Column>
          <Column field="nome" header="Nome" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nome</span>
              {{ slotProps.data.nome }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="editSetor(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDeleteSetor(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="setorDialog"
          :style="{ width: '480px' }"
          :header="titleDialog"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="nome">Nome</label>
            <InputText
              id="nome"
              v-model.trim="setor.nome"
              required="true"
              autofocus
              placeholder="Digite o nome do Setor"
              maxlength="255"
              :class="{ 'p-invalid': submitted && !setor.nome }"
            />
            <small class="p-invalid" v-if="submitted && !setor.nome"
              >Nome é obrigatório</small
            >
          </div>
          <div class="p-field">
            <label for="sigla">Sigla</label>
            <InputText
              id="nome"
              v-model.trim="setor.sigla"
              required="true"
              placeholder="Digite a sigla do Setor"
              maxlength="50"
              :class="{ 'p-invalid': submitted && !setor.sigla }"
            />
            <small class="p-invalid" v-if="submitted && !setor.sigla"
              >Sigla é obrigatória</small
            >
          </div>
          <template #footer>
            <Button
              label="Salvar"
              icon="pi pi-check"
              class="p-button-raised"
              @click="sendSetor"
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="hideDialog"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteSetorDialog"
          :style="{ width: '450px' }"
          header="Mensagem de Confirmação!"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="setor"
              >Tem certeza de que deseja excluir o registro
              <b>{{ setor.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deletesetor"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="deleteSetorDialog = false"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteSetoresDialog"
          :style="{ width: '450px' }"
          header="Mensagem de Confirmação!"
          :modal="true"
        >
          <div class="confirmation-content">
            <i
              class="pi pi-exclamation-triangle p-mr-3"
              style="font-size: 2rem"
            />
            <span v-if="setor"
              >Tem certeza de que deseja excluir os registros selecionados
              ?</span
            >
          </div>
          <template #footer>
            <Button
              label="Sim"
              icon="pi pi-check"
              class="p-button-raised"
              @click="deleteselectedSetores"
            />
            <Button
              label="Não"
              icon="pi pi-times"
              class="p-button-raised p-button-text"
              @click="deleteSetoresDialog = false"
            />
          </template>
        </Dialog>
      </div>
    </div>
    
  </div>
</template>

<script>

import AuthService from "@/service/Auth/AuthService";
import SetorService from "@/service/Setor/SetorService";
export default {
  data() {
    return {
      setores: null,
      setorDialog: false,
      deleteSetorDialog: false,
      deleteSetoresDialog: false,
      setor: {
        id: null,
        nome: null,
        sigla: null,
      },
      selectedSetores: null,
      filters: {},
      submitted: false,
      titleDialog: null,
      msg: null,
      msgUsuario: null,
      msgDesenvolvedor: null,
    };
  },
  setorService: null,
  authService: null,
  created() {
    this.authService = new AuthService();
    this.setorService = new SetorService();
  },
  mounted() {
    this.authService.checarToken(this.listarsetores());
  },
  methods: {
    listarsetores() {
      this.setorService.listar().then((data) => {
        this.setores = data;
        // console.log(this.setores);
      });
    },
    openNew() {
      this.titleDialog = "Novo Setor";
      this.submitted = false;
      this.setorDialog = true;
    },
    hideDialog() {
      this.setorDialog = false;
      this.submitted = false;
      this.setor = {};
    },
    sendSetor() {
      this.authService.checarToken(this.saveSetor());
    },
    saveSetor() {
      this.submitted = true;
      if (this.setor.id == null) {
        this.setorService
          .criar(this.setor)
          .then((data) => {
            if (data.status === 201) {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Registro salvo com sucesso!",
              });
              this.setores.push(data.data);
              this.hideDialog();
            }
          })
          .catch((error) => {
            this.msgUsuario = error.response.data[0].mensagemUsuario;
            this.msgDesenvolvedor =
              error.response.data[0].mensagemDesenvolvedor;
            console.log(this.msgDesenvolvedor);
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: this.msgUsuario,
            });
          });
      } else {
        this.setorService.atualizar(this.setor.id, this.setor).then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Registro atualizado com sucesso!",
            });
            this.hideDialog();
          }
        });
      }
    },
    editSetor(setor) {
      this.setor = setor;
      this.titleDialog = this.setor.nome;
      this.setorDialog = true;
    },
    confirmDeleteSetor(setor) {
      this.setor = setor;
      this.deleteSetorDialog = true;
    },
    deletesetor() {
      this.authService.checarToken(this.deletarSetor());
    },
    deletarSetor() {
      this.setorService.remover(this.setor.id).then((data) => {
        if (data.status === 204) {
          this.deleteSetorDialog = false;
          this.setor = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso!",
            detail: "Registro Deletado.",
            life: 3000,
          });
          this.listarsetores();
        } else {
          console.log(data);
          this.$toast.add({
            severity: "erro",
            summary: "Erro!",
            detail: "Erro ao deletar registro.",
            life: 3000,
          });
        }
      });
    },
    confirmDeleteSelected() {
      this.deleteSetoresDialog = true;
    },
    deleteselectedSetores() {
      for (var i in this.selectedSetores) {
        this.setor = this.selectedSetores[i];
        this.deletesetor();
      }
      this.deleteSetoresDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setor-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.p-dialog .setor-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.setor-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }
  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>